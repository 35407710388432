.sign-in-container {
  display: flex;
  flex-direction: row;
  width: 680px;
  height: 400px;
  position: relative;
  background: #fff;
}

.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f51b5;
  padding: 35px 35px 20px;
  width: 43%;

  & img {
    width: 100%;
  }
}

.logo {
  vertical-align: middle;

  & .logo-img {
    max-height: 140px;

    @media (min-width: 600px) {
      max-height: none;
    }
  }
}

.auth-layout {
  display: grid;
  place-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.sigh-in-section {
  padding: 20px;
  width: 60%;
  align-items: stretch;
}

@primary-color: #3FA9FF;